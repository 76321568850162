<template>
  <v-container fluid class="mx-4">
    <v-row v-if="item == null && this.$store.state.loading">
      <v-col width="100%" class="mx-auto mt-6">
        {{ $t("loading") }}
      </v-col>
    </v-row>
    <v-row v-if="item == null && !this.$store.state.loading">
      <v-col width="100%" class="mx-auto mt-6">
        {{ $t("no_data_found") }}
      </v-col>
    </v-row>
    <v-row v-if="item != null">
      <v-card width="90%" class="mx-auto mt-6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              item.name
            }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ $t("createdAt") }}:
              {{ item.createdAt | getDisplayDate }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("name") }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("description") }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("type") }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-icon :class="showReportType(item.type)">{{
                  showReportType(item.type)
                }}</v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("createdAt") }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.createdAt | getDisplayDate | empty }} ({{
                  item.$calculated.$createdBy | empty
                }})
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("updatedAt") }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.updatedAt | getDisplayDate | empty }} ({{
                  item.$calculated.$updatedBy | empty
                }})
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("status") }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip :color="getColor(item.status)" dark>{{
                  $t(item.status.split(".").pop())
                }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4 px-8">
          <v-icon class="mr-4 my-2" @click="deleteTemplate(item)"
            >mdi-delete</v-icon
          >
          <v-spacer></v-spacer>
          <v-icon class="mr-4 my-2" @click="editTemplate(item)"
            >mdi-pencil</v-icon
          >
          <v-spacer></v-spacer>
          <v-icon class="mr-4 my-2" @click="editAttachedFiles(item)"
            >mdi-file-plus</v-icon
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <!-- create/edit template -->
    <v-dialog v-model="dialogEditorVisible" persistent max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogEditorVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <GsTemplateEditor ref="templateEditor" @saved="modalSave" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- attached files -->
    <v-dialog v-model="dialogAttachedFilesVisible" persistent max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogAttachedFilesVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <GsTemplateFilesEditor ref="templateFilesEditor" @saved="modalSave" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";
import GsTemplateEditor from "@/components/GsTemplateEditor";
import GsTemplateFilesEditor from "@/components/GsTemplateFilesEditor";

export default {
  name: "TemplateDetails",
  components: {
    GsTemplateEditor,
    GsTemplateFilesEditor
  },
  data() {
    return {
      item: null,
      schedulerSimulatorVisible: false,
      timerSimulationData: null,
      dialogEditorVisible: false,
      dialogAttachedFilesVisible: false
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(value) {
        this.getTemplate(value.params.templateId);
      }
    }
  },
  methods: {
    async getTemplate(templateId) {
      try {
        this.$store.state.loading = true;
        const url = `${this.$store.state.email_manager_api}/templates?filter=templateId:in:[${templateId}]&r8sFields=createdBy.name,updatedBy.name`;
        const { data } = await callAPI({ url, method: "GET" });
        const item = data[0];
        item.$calculated = item.$calculated || {};
        this.item = await this.calculateProperties(item);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async calculateProperties(template) {
      template.$calculated = template.$calculated || {};
      template.$calculated.$createdBy = template.createdBy?.name;
      template.$calculated.$updatedBy = template.updatedBy?.name;
      return template;
    },
    async editTask(task) {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (this.$refs.taskEditor) {
          await this.$refs.taskEditor.edit(task);
        }
      });
    },
    async modalSave(templateId) {
      console.log("templateId", templateId);
      this.dialogEditorVisible = false;
      await this.getTemplate(templateId);
    },
    async deleteTask(taskId) {
      const modalResult = confirm(this.$t("delete_modal"));
      if (!modalResult) return;
      const url = `${this.$store.state.email_manager_api}/tasks/${taskId}`;
      const result = await callAPI({ url, method: "DELETE" });
      if (result.status === 204) {
        this.refreshTable(this.options.page);
      }
    },
    showSchedulerSimulator(item) {
      this.schedulerSimulatorVisible = true;
      this.timerSimulationData = {
        schedulingStartTime: dayjs(item.startTime)
          .add(20, "minutes")
          .toISOString(),
        schedulingEndTime: item.endTime,
        schedulingTimeData: {
          hour: item.hour,
          minute: item.minute,
          month: item.month,
          day: item.day,
          dayType: item.dayType
        },
        timezone: item.timezone
      };
    },
    showReportType(type) {
      switch (type) {
        case "summary":
          return "md-file-document";
        default:
          return "mdi-file-code";
      }
    },
    getColor(status) {
      switch (status) {
        case "template.status.active":
          return "green";
        case "template.status.inactive":
          return "grey";
        case "template.status.deleted":
          return "red";
      }
    },
    async editTemplate(template) {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateEditor) {
          return;
        }
        await this.$refs.templateEditor.edit(template);
      });
    },
    async deleteTemplate(template) {
      const result = confirm(`${this.$t("delete_modal")}\n\n${template.name}`);
      if (!result) {
        return;
      }

      try {
        const url = `${this.$store.state.email_manager_api}/templates/${template.templateId}`;
        await callAPI({
          url,
          method: "DELETE"
        });

        const index = this.templates.findIndex(
          p => p.templateId == template.templateId
        );
        this.templates.splice(index, 1); //deleted
        // await this.getTemplates()
      } catch (error) {
        console.log(error);
      }
    },
    async editAttachedFiles(template) {
      this.dialogAttachedFilesVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateFilesEditor) {
          return;
        }
        await this.$refs.templateFilesEditor.edit(template);
      });
    }
  }
};
</script>
